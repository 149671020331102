#var-fert-map {
  background-color: grey;
}

#var-fert-map .ol-zoom {
  left: unset;
  top: unset;
  right: 15px;
  bottom: 28px;
}

#var-fert-map .ol-attribution {
  font-size: 11px;
}

#var-fert-map .ol-control,
#var-fert-map .ol-control:hover {
  padding: 0px;
  background: none;
}

#var-fert-map .ol-overlaycontainer-stopevent .ol-zoom button,
#var-fert-map .ol-control button,
#var-fert-map .ol-control button:focus {
  color: black;
  background-color: #ffffff;
  width: 32px;
  height: 32px;
  margin: 0px;
  border-radius: 4px;
}

#var-fert-map .ol-control button.ol-zoom-in {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

#var-fert-map .ol-control button.ol-zoom-out {
  height: 33px;
  border-top: 1px solid #e0e0e0;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

#var-fert-map .ol-overlaycontainer-stopevent .ol-zoom button:hover,
#var-fert-map .ol-control button:hover {
  color: #00a179;
}

#var-fert-map .ol-overlay-container .hover-hint {
  border-radius: 4px;
  padding: 5px 15px;
}

#var-fert-map .ol-overlay-container .hover-hint p {
  font-weight: bold;
  margin: 0;
  font-size: 12px;
  text-align: center;
}

#var-fert-map .ol-overlay-container .var-fert-area-info {
  background-color: #fcd783;
}

#var-fert-map .ol-overlay-container .var-fert-area-info p {
  color: #000000;
}

#var-fert-map .ol-overlay-container .var-fert-area-info p:last-child {
  font-weight: normal;
}

#var-fert-map .ol-overlay-container .var-fert-area-info p:first-child {
  font-weight: bold;
}

#var-fert-map .ol-overlay-container .var-fert-disabled-info {
  background-color: #333333;
}

#var-fert-map .ol-overlay-container .var-fert-disabled-info p {
  color: #ffffff;
}
